import React, { useReducer, useState } from 'react';
import { useEffect } from 'react';


import _ from 'lodash';

import config from '../../config'

export const getPartsOptions = async () => {
  const response = await fetch(`${config.backend_api_url}/silk/partsOptions`);
  const json = await response.json();
  return json;
}

export const SelectedOption = React.createContext({
  selectedOption: {},
  dispatch: () => {}
})

export const SelectedOptionContext = (props) => {
  const [ partsOptions, setPartsOptions ] = useState({})

  const reducer = (state, {path, newobject}) =>{
    if (path == "" | path == []){
      return {...state,...newobject}
    }else{
      const oldstate = structuredClone(state)
      const newstate = _.update(oldstate, path, function(oldvalue){
        return {...newobject}
      })
      console.log("SelectedOptionContext reducer newobject", newobject)
      console.log("SelectedOptionContext reducer path", path)
      console.log("SelectedOptionContext reducer newstate",newstate)
      console.log("SelectedOptionContext reducer oldstate",oldstate)
      return newstate
    }
  }
  const init = async (partsOptions) => {
    const allOptions = partsOptions;
    const getFirstOptionRecursive = (parent) =>{
      const option = structuredClone(parent[0])
      if (option.hasOwnProperty('colors')){
        option.color = getFirstOptionRecursive(option['colors']);
      }
      delete option.colors
      return option
    }
    const initOptions = Object.fromEntries(Object.entries(allOptions).map(
      ([k, v], i) => [k, getFirstOptionRecursive(v)]
    ))
    return initOptions
  }

  const [selectedOption, dispatch] = useReducer(reducer, partsOptions)
  const [isBusy, setIsBusy] = useState(true)
  const [isInitialized, setIsInitialized] = useState(false)

  const defaultoption = async ()=>{
    setIsBusy(true)
    const partsoptions = await getPartsOptions();
    const defaultoption = await init(partsoptions);
    setPartsOptions(partsoptions);
    dispatch({path: [], newobject: defaultoption})
    console.log("partsoptions", partsoptions)
    console.log("defaultoption", defaultoption)
    setIsBusy(false)
    setIsInitialized(true)
  }
  useEffect(()=>{
    if (!isInitialized) defaultoption();
  })


  const context = { 
    partsOptions: partsOptions,
    selectedOption: selectedOption,
    dispatch: dispatch,
    isBusy: isBusy,
  }

  return(
    <SelectedOption.Provider value={context}>
      {props.children}
    </SelectedOption.Provider>
  )
}
