import React, { useState, useCallback, useRef, useEffect, useContext } from 'react';
import { ToastContainer } from 'react-toastify';
import styled from 'styled-components';


import { WalletProviderContext, WalletConnectButton, walletGetNFT, walletGetNFTmetadata } from '../../components/WalletConnectButton'
import { useConnection, useWallet } from '@solana/wallet-adapter-react';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';

import ColorThief from 'colorthief'
import {SelectedOptionContext, SelectedOption} from '../../components/SilkOptionContext'

import config from '../../../config'
config.network = WalletAdapterNetwork.Devnet;


const PreviewImage = styled.img`
  width: 100%;
`
async function getBase64Image(url) {
  const response = await fetch(url);
  const blob = await response.blob();
  const reader = new FileReader();
  await new Promise((resolve, reject) => {
    reader.onload = resolve;
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
  return reader.result.replace(/^data:.+;base64,/, '')
}

const Preview = ({nft}) => {
  const [img, setImg] = useState(nft.metadata.image);
  const [ isLoading, setIsLoading ] = useState(true)
  const { selectedOption, isBusy } = useContext(SelectedOption)
  useEffect(async() => {
    console.log("Preview selectedOption",selectedOption)
    console.log("Preview isBusy",isBusy)
    if (!isBusy) fetchImage();
  },[selectedOption])


  const fetchImage = async () => {
    setIsLoading(true)
    const body = {
      nft: nft,
      selectedOption: selectedOption
    }
    console.log("preview body:", body)
    try {
      const res = await fetch(`${config.backend_api_url}/silk/preview`,{
        method: "POST",
        body: JSON.stringify(body),
        headers: {
          'content-type': 'application/json'
        },
      });;
      if (res.ok) {
        const imageBlob = await res.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob); 
        setImg(imageObjectURL);
      } else {
        setImg(nft.metadata.image);  
      }
    } catch (error) {
      setImg(nft.metadata.image);
    } finally {
      setIsLoading(false);
    }
  };



  return (
      <div>
        <PreviewImage key={nft.metadata.name} src={img}/>
        { isLoading||isBusy? <p>loading...</p>: "" }
      </div>
  )
  // return <canvas ref={canvasRef}/>
}

const PartsOptionItem = ({option, value}) => {
  return (
    <option value={value}> {option.displayName} </option> 
  )
}

const PartsColorOptionItem = ({option, value}) => {
  const [ loaded, setLoaded ] = useState(false)
  const [ colorCode, setColorCode]  = useState()

  // const getColorCode = async () => {
  //   const dominantColor = await getColorFromURL(imageURL);
  // };



  const onImageLoaded = (event)=>{
    console.log("onImageLoaded")
    setLoaded(true)
    ColorThief.getColor(Image)
      .then( c => setColorCode(c) );
  }

  const Image = <img src={option.image} onLoad={onImageLoaded}/>
}


const PartsOption = ({partsName, options, selectedOptionParentPath}) => {
  const { selectedOption, dispatch } = useContext(SelectedOption);

  const [ partsValue, setPartsValue] = useState(options[0])
  let suboption = ""
  
  useEffect(() => {
    const newOption = structuredClone(partsValue);
    delete newOption.colors;
    dispatch({ path: selectedOptionParentPath, newobject: newOption })
  },[partsValue])

  useEffect(() => {
    setPartsValue(options[0])
  },[options])

  const onPartsSelectChange = (partsName, event) => {
    const index = event.target.value;
    setPartsValue(options[index])
  }

  return (
    <>
      <select id={partsName} name={partsName} onChange={ e => onPartsSelectChange(partsName,e)} value={options.indexOf(partsValue)}>
        {
          options.map( (option, index) => {
            return <PartsOptionItem key={index} option={option} value={index}/>
          })
        }
      </select>
      { partsValue.hasOwnProperty('colors')
        ? <PartsOption key={partsName} partsName={partsName} options={partsValue['colors']} selectedOptionParentPath={ [...selectedOptionParentPath, 'color'] }/>
        : ""
      }
    </>
  )
}

const NftPartsSeletor = () => {
  const { partsOptions, selectedOption, isBusy } = useContext(SelectedOption);

  const child = 
    isBusy
    ? "loading..."
    : Object.entries(partsOptions).map( ([partsName, options]) => {
          return (
            <div key={partsName}>
              <label htmlFor={partsName}>Choose a {partsName}:</label>
              <PartsOption key={partsName} partsName={partsName} options={options} selectedOptionParentPath={[partsName]}/>
            </div>
          )
        })

  return (
    <div>
      { child }
    </div>
  )
}


const NftEditorContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`

const NftEditor = ({nft}) => {
  if (nft) {
    return(
      <NftEditorContainer>
        <SelectedOptionContext>
          <Preview nft={nft} />
          <NftPartsSeletor />
        </SelectedOptionContext>
      </NftEditorContainer>
    )
  } else {
    return null
  }
}

const SilkNftSelectorSection = ( { nftFilter, setNftSelected } ) => {
  const [ nftOwned, setNftOwned ] = useState([])

  const { publicKey } = useWallet();
  const { connection } = useConnection();

  const onNftGetClick = useCallback(async () => {
    try {
      
      const nfts = (await walletGetNFT(publicKey, connection)).filter( nft => nftFilter.includes(nft.mint) );
      const nfts_with_metadata = await walletGetNFTmetadata(nfts);
      setNftOwned(nfts);

      console.log("nfts", nfts_with_metadata);

    } catch (error) {

      console.error(error);
      throw(error)

    }
        
  },[publicKey, connection]);

  return (
    <>
      <button onClick={onNftGetClick}> Get NFT Owned</button>
      <div style={{ display: "grid", gridTemplateColumns: "repeat(6, 1fr)", gridGap: 20 }}>
        {
          nftOwned.map( nft => 
            <img key={nft.metadata.name} src={nft.metadata.image} style={{ width: "100px" }} onClick={ e => {console.log("NFT seleted:", nft); setNftSelected(nft)} }/>
          )
        }
      </div>
    </>
  )
}

const SilkGeneratorPage = () => {
  const [nftSelected, setNftSelected] = useState()
  const [nft2Check, setNft2Check] = useState(["AAJaLem5iSQSkfkV454wvXLbzrvNRZobUZ3Zj7D62vkp"])
  
  return (
    <>
      <ToastContainer />
      <WalletProviderContext network = { config.network } >
        <title>IRON POC</title>
        <h1>IRON silk generator Page</h1>
        <h3>Wallet Connect test</h3>
        <WalletConnectButton />
        <h3>Wallet NFT select test</h3>
        <SilkNftSelectorSection nftFilter={nft2Check} setNftSelected={setNftSelected}/>
        <h3>NFT editor</h3>
        <NftEditor nft={nftSelected}></NftEditor>

      </WalletProviderContext>
    </>
  )
}

export default SilkGeneratorPage;