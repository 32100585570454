
import cfg from './config.json'

// import doc from './config.yml'

// var env = process.env.NODE_ENV || 'development'

// const isInLambda = !!process.env.LAMBDA_TASK_ROOT;
// if (isInLambda){
//   env = 'production'
// }


// const cfg =  import(`./${env}.yml`)

console.debug("cfg", cfg);

export {cfg as default}